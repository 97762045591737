.main-container {
    justify-content: space-between;
    display: flex;
    gap: 20px;
  }

  @media (max-width: 991px) {
    .main-container {
      flex-wrap: wrap;
    }
  }

  .logo-img {
    aspect-ratio: 1.47;
    object-fit: contain;
    object-position: center;
    width: 78px;
    height: 53px;
    overflow: hidden;
    max-width: 100%;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
  

  }
  .navbar-menu-container {
    display: none;
  }
  .navbar-links-container{
    /*position: absolute;*/
    right: 30px;
    display: flex;
   }
   .navbar-links-container a {
     margin-right: 24px;
     text-decoration: none;
     color: #292D34;
     font-size: 17.5px;
     font-weight: 500;
   }
   .light-header a{
    color: #fff;
   }
   .navbar-menu-container svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
  .list-item{
    border-bottom: 1px solid rgba(41, 45, 52, 0.1);
  }
  .menu-mobile-item{
    text-decoration: none;
  }
  .menu-mobile-item span{
    margin-right: 24px;
      
      color: #292D34 !important; 
      font-size: 17.5px !important;
      font-weight: 500 !important;
      font-family: 'Baloo 2', sans-serif !important;
  }
  .navigation {
    align-items: flex-start;
    align-self: center;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: auto 0;
    padding: 0 20px;
  }
  .navigation a{
    text-decoration: none;
  }
  .light-link-lang{
    color: #000 !important;
  }

  @media (max-width: 991px) {
    .navigation {
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .link {
    text-align: center;
    align-self: center;
   
    
    margin-right: 24px;
    text-decoration: none;
    color: #292D34;
    font-size: 17.5px;
    font-weight: 500;
  }

  .divider {
    background-color: rgba(41, 45, 52, 0.2);
    align-self: center;
    width: 1px;
    height: 22px;
    margin-right: 20px;
    display: inline-block;
  }
  
.menu-icon{
  color: #292d34;
    width: 45px;
    background: rgba(129,122,153,0.14);
    height: 45px;
    position: relative;
    padding: 10px;
    border-radius: 50%;
}
.menu-icon-light{
  color: #fff;
    width: 45px;
    background: rgba(255,255,255,0.14);
    height: 45px;
    position: relative;
    padding: 10px;
    border-radius: 50%;
}
  .language-icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
    margin-top:8px
  }
  .close-menu{
    width: 24px;
    float: right;
    margin: 20px 20px 0px 20px;
    color: rgba(41, 45, 52, 1);
  }
  .close-EV{
    background: none;
    border: none;
    float: right;
    position: absolute;
    right: 0 !important;
    z-index: 999999;
  }
  .close-AV{
    position: absolute;
    left: 0 !important;
    z-index: 999999;
    background: none;
    border: none;
    float: left;
  }
  .css-1ontqvh{
    position: static !important;
  }
  .css-h4y409-MuiList-root{
    position: static !important;
  }
  .right-section{
    margin-top: 10px;
  }
  /* Footer Style*/
  .footer-section{
    position: relative;
  }
  .footer-container{
    background-image: url(../../Assets/footer-background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 250px 280px 66px 280px;
    position: absolute;
    top: -130px;
    z-index: -100;
    text-align: center;
    margin: auto;
    width: 100%;
  
  }
  .information-section{
    text-decoration: none;
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 10px 0px;
    text-align: center;
    margin: auto;
    justify-content: space-evenly;
  }
  .information-section a{
    text-decoration: none;
  }
  .email, .phone, .location{
    display: flex;
    gap: 11px;
    align-items: center;
  }
  .info-text{
    font-size: 18px;
    color:#000;
    font-weight: 500;

  }
  .info-icon{
    color: #DF1995;
    border: 2px solid rgba(223,25,149, 0.1);
    width: 39px;
    height: 39px;
    padding: 5px;
    border-radius: 50%;
  }
  .menu-section{
    padding: 24px 15px;
    text-align: center;
  }
  .footer-links-container a {
    margin: 20px;
    text-decoration: none;
    color: #292D34;
    font-size: 17.5px;
    }
    .break-mobile{
      display: none;
    }
    @media (max-width: 1000px) {
      .navbar-links-container a {
        margin-right: 1rem;
        font-size: 1rem;
      }}
    @media (max-width: 760px) {
      .logo-img{
        width: 55px;
      }
   
        .nav-logo-container {
          max-width: 140px;
        }
        .navbar-links-container {
          display: none;
        }
        .navbar-menu-container {
          display: flex;
        }
      .information-section{
        flex-direction: column;
        margin: auto;
        text-align: center;
        align-items: flex-start;
        align-content: center !important;
        flex-wrap: wrap;
        gap:20px


      }
      .footer-container {
        padding: 270px 0px 30px 0px;
        text-align: center;
        top: -230px
      }
      .footer-links-container a{
        margin: 10px;
      }
      .break-mobile{
        display: block;
      }
      .arabic-service .features-container{ width: 100% !important}
      .arabic-service .trainers-container {width: 100% ; margin-top: 5px !important ; padding: 20px 0px !important;}

      .trainers-presenter{
        flex-direction: column;
      }
      .terms-section{
        padding: 30px 10px 10px 10px !important;
      }
      .terms-section .title{
        font-size: 24px;
      }
      .terms{
        padding: 20px 0px !important;
      }
      .term{
        padding: 15px 0px !important;
      }
      .quiz-label{
        /*flex: 1 1 0%;*/
        min-width: 100% !important;
        font-size:14px !important;
        background-color: transparent !important;
      }
      .quiz-label .container{
        max-width: 100% !important;
         width: 100% !important;
      }
      .thank-quiz .modal-container{
      flex-direction: column !important;}
    }


    a.active{
      color: #DF1995;
    }
    a.not-active{
      color :#000 !important
    }
    .right-section .menu{
      position: relative;
    }
    .right-section ul {
      list-style: none;
      padding: unset;
      margin: unset;
    }
    li{
      padding: 14px;
      
    }
    
     li a{
      font-size: 14px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;
    }
    
    .right-section li:hover {
      background: gray;
      cursor: pointer;
      color: #fff;
    }
    
    .dropdown-menu {
      position: absolute;
      z-index: 99999;
      background: #fff;
      box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
      transition: all linear 0.3s;
    }
    .dropdown-menu li:hover{
      background-color: #DF1995 !important;
    }
    .dropdown-menu li:hover a{
      color: #fff !important

    }
    .lang-flag{
      width: 15px;
      height: 15px;
      float: left;
    }
  /*Arabic version */
  .arabic-nav {
    direction: rtl;
    
}
.arabic-menu{
  direction: rtl !important;

}
.arabic-advertise{
  direction: rtl;
}
.arabic-advertise-image img{
    transform: scale(-1, 1);
}
.arabic-advertise-text{
    width: 500px;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.modal-bg {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.modal-container {
  border-radius: 10px;
  background: #fff;
  position: relative;
  padding: 30px;
  width: 100%;
  max-width: 320px;
  text-align: center;
}

.modal-close {
  position: absolute;
  right: 5px;
  top: 1px;
  outline: 0;
  appearance: none;
  color: #817A99;
  border: 0;
  font-weight: 700;
  cursor: pointer;
  background: #fff;
  padding: 0px 5px;
  font-size: 20px;
}

.modal h2 {
  font-size: 24px;
  color: #0aa0fa;
  margin: 0;
}

.modal p {
  color: #817A99;
}
.modal img {
  width: 200px;
}
.modal strong {
  width: 200px;
}
/*quiz modal*/

.form-modal ,.quiz-modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
} 
.form-modal.open, .quiz-modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.form-modal-bg, .quiz-modal-bg {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.form-modal-container  ,.quiz-modal-container {
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  border-radius: 10px;
  background: #f6f6f6;
  position: relative;
  padding:100px 20px;
  width: 97%;
  max-width: 100%;
  height: 100%;
  text-align: center;
}
.form-modal-close , .quiz-modal-close {
  position: absolute;
  right: 20px;
  top: 10px;
  outline: 0;
  appearance: none;
  color: #DF1995;
  border: 0;
  font-weight: 700;
  cursor: pointer;
  background: transparent !important;
  padding: 5px 10px;
  font-size: 50px;
}
#terms-label{
  left:unset!important;
}
.workshops-arabic .our-services-container2{
  flex-wrap: nowrap;
}
.invalid-code{color:red;}
/*terms page*/
.terms-section{
  padding: 100px 100px 2px 100px;
}
.terms-section .title{
  color: #DF1995;
}
.terms{
  padding: 30px 0px;
}
.term{
  padding: 15px;
}
/*tick icon */

@supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw 0.5s cubic-bezier(0.25, 0.25, 0.25, 1) forwards;
    animation-delay: 0.6s;
 }
  .circle {
    fill-opacity: 0;
    stroke: #219a00;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(0.25, 0.25, 0.25, 1.25) forwards;
 }
}
@keyframes grow {
  60% {
    transform: scale(0.8);
    stroke-width: 4px;
    fill-opacity: 0;
 }
  100% {
    transform: scale(0.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #219a00;
 }
}
@keyframes draw {
  0%, 100% {
    stroke-opacity: 1;
 }
  100% {
    stroke-dashoffset: 0;
 }
}

/*quiz*/

.step-title{
  
padding: 40px 50px;
text-align: center;
background-color: #fff;
width: 100%;
border-radius: 20px;
margin: 100px auto 20px;
}

.step-title-light{
  background-image: url(../../Assets/quiz-b-1.png),url(../../Assets/quiz-b-2.png) ,url(../../Assets/backround-without-element.svg)  , linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%);
  background-position:
    bottom left,
   top right,
    center;
  color: #fff;
  background-size: contain ,contaion, cover;
  background-repeat: no-repeat;
  background-color: transparent !important;

}
.SBD-quiz .step-title-light{
  background-image: url(../../Assets/cbd-quiz-1.png),url(../../Assets/cbd-quiz-2.png),url(../../Assets/backround-without-element.svg)  , linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%);
  background-position:
    bottom left,
   bottom right,
    center;
  color: #fff;
  background-size: contain, contain, cover;
  background-repeat: no-repeat;
  background-color: transparent !important;

}
.introduction-quiz-form{
  width: 100%;
border-radius: 20px;
margin: auto;
text-align: center !important;
margin: 30px 0px 

}

.formQuiz .introduction-quiz-form{
  margin: 0 !important;
  text-align: left !important;
}
.formQuiz textarea{
  width: 100%;
  height: 100px;
  border:  1px solid #e7e7e7; 
  border-radius: 12px;
  margin: 10px 0px 0px 0px;
  padding: 15px;
}
textarea::placeholder{
  padding: 10px 20px;
  font-size: 18px;
}
.introduction-quiz-form input , .introduction-quiz-form select{
  display: block;
  background-color: #fff !important;

  width: 100%;
  margin: 30px 0px;
  border: 1px solid;
    border-color: #e7e7e7;
    border-radius: 12px;
    padding: 0px 24px;
    position: relative;
    
    font-size: 16px;
}
.next-button{
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  background-color: #DF1995;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
 
  
}
.back-button{
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  background-color: #8e8e8e;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 100px;
 
}
.continue-button{
  display: flex;
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  background-color: #DF1995;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.quiz-steps{
  width: 90% !important;
}
.quiz-form{
  width: 60%;
  text-align: right !important;
  margin: auto;
}
.q1o1{
  width: 100px;
  height: 100px;
}
.images-form{
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 30px;
margin: 30px 0px;
}
.images-form .quiz-label{
  min-width: auto !important;
}
.multi-check-text2 .quiz-label{
  min-width: 500px !important;

}
.multi-check-text2 .container{
  margin: 0px !important;
}
.images-form2 img{
  width: 270px;
}
.three-images{
  flex-wrap:nowrap !important;
}
.three-images .quiz-label{
  min-width: auto !important;
}
.introduction-quiz-form .container{
  position: relative; width: 500px;   border-radius: 20px; padding: 15px; background-color: #fff;
}

.introduction-quiz-form  .checkbox { position: absolute; bottom: 0px; right: 0px; width: 30px; }
.introduction-quiz-form input[type=checkbox]{
  margin: 0px;
  transform: scale(1.3);
  top:18px;
  left: 17px;
}
.formQuiz .introduction-quiz-form input[type=checkbox]{
  top:17px;
  left: 17px;
}
.option-desc{
  font-weight: 500;
  line-height: 100%;
  text-align: left;
}
.multi-check-text .container{
  width: 100%;
  margin: 12px 0px
}
.formQuiz .introduction-quiz-form input, .formQuiz .introduction-quiz-form select{
  margin: 1px 0px;
}
.formQuiz .multi-check-text .container{
  margin: 10px 0px !important;
  border: 1px solid #e7e7e7;
}
.terms-container{
  border: none !important;
  padding: 40px 20px 20px 2px;
  text-align: left !important;
  display: flex;
  flex-direction: row !important;
}
.terms-container input[type=checkbox]{
  bottom: 12px;
  top: -5px;
  right: 5px;
}
.date-form{
  display: flex;
  align-items: center;
}
.multi-check-text .option-desc{
margin-left: 30px;
font-size: 18px;
font-weight: 600;
line-height: normal !important;
}
.open1{
  visibility:visible
}
.close1{
  visibility: hidden;
}
.take-quiz{
  background-color: #DF1995;
  border: none !important;
  padding: 10px 30px;
  color: #fff;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;
}
.living-page .take-quiz{
  background-color: #FFCD00;
  color:#000;
  font-size: 28px;
}
.content-page .take-quiz{
  background-color: #FFCD00;
  color:#000;
  font-size: 28px;
}
 .container.pink, .container.pink label {
  background-color: #DF1995 !important;
  color: #fff
}
.container.white, .container.white label {
  background-color: #fff !important;
  color: #000;
  cursor: pointer;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  
  right: 5px;
  top: 12px;
 
  z-index: 1000;
}
.quiz-label{
  background-color: white !important;
  position:static !important;
    border-radius: 20px;
   
    padding: 0px !important; 
    font-size: 18px; 
    /* font-weight: 500;*/
}
.three-by-line .container{
  width: 300px !important;
}

.formQuiz .quiz-label{
background-color: transparent !important;
}

@media (max-width: 990px) {
  .introduction-quiz-form .container{
    flex:1;
min-width: 40%
  }}
.dimensions{
  width: 100px !important;
  display: inline !important;
}
.react-datepicker-wrapper, .introduction-quiz-form select {
  line-height: normal !important;
  margin: 10px;
  display: inline-block !important;
  height: 58px;
    width: 407px;
}
.terms-container{
  background-color: transparent !important;
}
.quantity{
  font-size: 14px;
  padding: 10px 0px;
  text-align: left !important;
}
.quantity-tool{
  padding: 0px 10px;
  margin: 0px 10px;
  background-color: #e2e2e2;
  color: #DF1995;
  border-radius: 3px;
  font-size: 18px !important;
  font-weight: 600;
  cursor: pointer;
}
.question{
  padding: 20px 0px;
}
.mobile-breakline{
  display: none;
}

/*quiz on mobile*/
@media (max-width: 760px) {
  .SBD-quiz .step-title-light{
    background-image: url(../../Assets/cbd-quiz-1.png),url(../../Assets/cbd-quiz-2.png),url(../../Assets/backround-without-element.svg)  , linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%);
    background-position:
      bottom left,
     bottom right,
      center;
    color: #fff;
    background-size: 40px, 40px, cover;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
  
  }
  .living-page .take-quiz{
    line-height: 30px;
    width: 65%;
    padding: 10px !important;
    font-size: 24px;
  }
  .content-page .take-quiz{
    line-height: 30px;
    width: 65%;
    padding: 10px !important;
    font-size: 24px;
  }
  .mobile-breakline{
   display: inline-block;
  }
  .formQuiz .quiz-form > div > div{
  flex-direction: column;
  }
.quiz-form {
  width: 100%;
  text-align: center !important;
  }
  .quiz-form > div > div{
    display: flex !important;
  }
  .step-title{
   padding: 20px 40px;
    font-size: 16px !important;
    margin: 44px auto 20px;
  }
  .introduction-quiz-form  .dimensions{
    width: 100px !important;
      }
  .introduction-quiz-form input, .introduction-quiz-form select{
    width: 100% !important
  }
  .images-form{
    gap: 10px;
    flex-direction: column;
  }
.multi-check-text2{
  flex-direction: column;
}
.multi-check-text2 .quiz-label{
  min-width: 100px !important;
}
.multi-check-text2 .option-desc {
  margin-left: 50px;
  padding: 10px 0px !important;}
  /*.images-form label:last-child{
    width: 50% !important;
    max-width: 50%;
  }*/
  .images-form .container {
max-width: 100% !important;
display: flex;
align-items: center;
gap: 7px;

  }
  .images-form .container img{
    width: 65%;
  }
  .living-page .images-form .container img{
    width: 60%;
  }
  .multi-check-text .option-desc{
    font-size: 14px;
  }
  .option-desc{
    padding: 5px;
  }
  .continue-button{
    padding: 10px 17px !important;
  }
  .next-button, .continue-button, .back-button{
    font-size: 16px !important;
  }
  .form-step-title{
    font-size: 18px !important;
  }
  .react-datepicker-wrapper, .introduction-quiz-form select{
    max-width: 350px;
  }
  .images-form .container.white{
    padding: 5px;
    background-color: transparent !important;
  }
  .images-form .container.pink{
    padding: 5px;  }
  .white img{
    border-radius: 20px;
  }
  .pink img{
    border-radius: 20px;

  }
  .without-desc .option-desc{
    display: none;
  }
 
  
  .without-desc .quiz-label{
    min-width: 40% !important;
  }
  .without-desc .container img{
    width: 100% !important;
  }
  .multi-check-text .option-desc{
    padding: 0px;
  }
}
  
.form-step-title{
  font-size: 24px ;
  text-align: left;
}
.total-price{
  text-align: left;
}
.border-red{
  outline: 1px solid red;
}
.terms-section li{
   padding: 0px !important
}


.card-slider-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
}

.card-slider {
  display: inline-flex; /* Display cards in a row */
  gap: 10px; /* Adjust gap between cards */
}

.card1 {
  flex: 0 0 calc(50% - 5px); /* Display two cards per row */
  max-width: calc(50% - 5px); /* Set maximum width for each card */
  /* Add additional styling for your cards */
}

@media (min-width: 768px) {
  .card1 {
    flex: 0 0 calc(33.33% - 10px); /* Display three cards per row on larger screens */
    max-width: calc(33.33% - 10px);
  }
}
.cta-buttons{
  position: fixed;
    right: 10px;
    bottom: 10px;
   z-index: 100;
}
.whats-button{
  background-color: transparent;
  border: none;
  width: 40px !important;
}
#yt-video{
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  border: 0;
  border-radius: 10px;
}


.video-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: auto;
}

.video-thumbnail,
.video-player {
  width: 100%;
  height: auto;
  display: block;
}

.video-thumbnail {
  cursor: pointer;
  border-radius: 8px;
}

.video-player {
  border-radius: 8px;
}

body.blur-background {
  filter: blur(5px); /* Blur effect on the entire page */
  overflow: hidden;  /* Prevent scrolling when video is expanded */
}

.video-container {
  position: relative;
  overflow: hidden; /* Ensure nothing overflows */
  border-radius: 10px; /* Rounded corners */
  transition: all 0.5s ease; /* Smooth transition */
}

.thumbnail-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.video-player {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px; /* Same rounded corners */
  z-index: 1; /* Layer the video below the close button */
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none; /* Transparent background */
  border: none;
  cursor: pointer;
  z-index: 2; /* Ensure play button appears above thumbnail */
}

.play-button img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

.play-button:hover {
  opacity: 0.8; /* Hover effect */
}

/* Modal container for the expanded video */
.video-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90vw;  /* 90% of the viewport width */
  transform: translate(-50%, -50%);
  z-index: 1000; /* Make sure it appears on top */
  background: transparent; /* No background to avoid white space */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* Maintain rounded corners */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

/* Overlay to place controls on top of the video */
.video-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  background: none;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2; /* Higher than the video */
}


.slider-container {
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between; /* To space out the arrows */
}

.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center; /* Center the content */
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 2em;
  color: #DF1995; /* Adjust color as needed */
  transition: color 0.3s;
  padding: 10px;
}

.nav-button:hover {
  color: #000; /* Adjust hover color as needed */
}

.compare-slider-wrapper {
  position: relative;
  width: 100%;
}

.badge {
  position: absolute;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  z-index: 2; /* Ensure the badge stays above the image */
}

.compare-badge.before {
  top: 10px;
  left: 10px;
}

.compare-badge.after {
  top: 10px;
  right: 10px;
}

.dots-container {
  text-align: center;
  margin-top: 15px;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #DF1995;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}