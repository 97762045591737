/*Style magic touch Studio */

.hero-section{
    background-image: url('../../../Assets/banner-service-background2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   
}

.banner-service{
    padding-top: 5%;
}
.light-heading{
    color: #fff;
    font-size: 48px;
}
.light-text{
    color: #fff;
    margin : 55px auto 42px;
}
.light-heading2{
    font-weight: normal !important;
}
.primary-button{
    color: #DF1995;
text-align: center;justify-content: center;
display: flex;
background-color: #ffffff;
border-radius: 50px;
box-sizing: border-box;
align-items: center;
text-decoration: none;
margin: auto;
padding: 11px 28px;
position: relative;

}
.banner-service-image{
    margin: auto;
    padding: 20px;
    padding-top: 150px;
}

.features h2, .testimonials h2 , .trainers h2{
    font-size:  52px !important;
    
}
.features h2{
    padding: 50px 0px 10px 0px;
}
.features-container , .testimonials-container{
    display: flex;
    gap: 32px;
    width: 100%;
    justify-content: center;
    margin-top: 60px !important;
}

.feature{
    background-image: url(../../../Assets/feature-image2.svg);
    background-repeat: no-repeat;
    width: 258px;
    /*min-height: 255px;*/
    border-radius: 20px;
}
.living-page .feature, .content-page .feature{
    background-image: url(../../../Assets/feature-image3.svg);
    width: 340px;
    
}
.living-page .trainers-container{
    align-items: center;
}
.living-page .second-features .features-container{
    padding: 0px 50px;
}
.living-page .second-features .dot___3c3SI{
    margin-top: 40px;
}
.feature-icon{
    padding: 20px;
}
.feature-desc{
    color: #000 !important;
    padding: 20px;
    font-weight: 500;
    font-size: 16px;
    white-space: pre-wrap;

}
.living-page .feature-desc , .content-page .feature-desc{
    padding: 10px 20px 30px 20px;
}
.feature-heading{
    padding: 10px 20px 0px 20px;
    
    font-weight: bold;
    font-size: 20px;
}
.living-page .feature-heading , .content-page .feature-heading{
    line-height: 35px;
}

.video-section{
    padding : 100px 30px;
    margin: auto;
    max-width: 1200px;
}
.video-section video{
    width: 100%;
    background-color: #000;
border-radius: 20px;}
.testimonials{
    padding: 100px 0px;
}
.testimonail{
    display: flex;
    align-items: center;

    background-color: #fff;
    /*width: 355px;*/
    /*height: 364px;*/
    padding: 30px;
    gap: 30px;
    text-align: right;
    border-radius: 20px;
/*box-shadow: 0px 5px 60px #817a9924;*/
}
.testi-desc{
    color: #817a99;
font-size: 20px;
font-weight: 500;
/*height: 185px;*/
line-height: 33px;
padding: 10px;
direction: rtl;

}
.testi-desc a{
    color: #DF1995;
    font-size: 14px;
    font-weight: bold;
}
.testi-info{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    flex-direction: column;

}

.name{
  font-size: 18px; 
}
.booking{
    padding-top: 40px;
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;

}
.clinic{
    padding-top: 80px;
}
.booking .left-section{
    width: 570px;
}
.booking .right-section{
    width: 500px;
}
.left-section-container .secondary-heading{
    text-align: left;
}

.left-section-container .third-heading{
    font-weight: 500 !important;
    color: #817a99 !important;
    line-height: 126%;
    margin: 20px 0px ;
}
.input-wrapper {
    position: relative;
  }
  
  input  {
    border: 1px solid;
border-color: #e7e7e7;
border-radius: 12px;
padding: 0px 24px;
    position: relative;
    height: 58px;
    width: 407px;
    font-size: 16px;
    color: #000;
    line-height: 6px;
  }
  
  label {
    position: absolute;
    top: -10px;
    z-index: 1;
    left: 20px;
    background-color: white;
    padding: 0 5px;
    color: #848D94;
    font-size: 12px;
    font-weight: 500;
  }

  .form-section form{
    background-color: #fff;
    border-radius: 20px;
    border: 1px #E9EAEA solid;
    box-shadow: 0px 3px 60px #817a992b;
    padding: 46px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .react-tel-input .special-label{
    white-space: nowrap;
    position: absolute;
    top: -10px;
    z-index: 1;
    left: 20px !important;
    background-color: white;
    padding: 0 5px;
    color: #848D94;
    display: block !important;
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .react-tel-input .form-control{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    outline: none;
    border: 1px solid !important;
border-color: #e7e7e7 !important;
border-radius: 12px !important;
padding: 0px 24px;
    position: relative;
    height: 58px !important;
    width: 407px !important;
    font-size: 16px;
    color: #000;
    line-height: 6px;
}
.react-tel-input .flag-dropdown{
    background-color: transparent !important; 
     border: none !important;
}
.question-workshop{
   display: flex;
   gap: 40px;
   margin-top: 10px;
}
.question-type{
    margin-top: 10px;
}
input[type=checkbox] {
    
    height: 18px;
    /*position: absolute;*/
    top: 3px;
    display: inline-block;
    width: 18px !important;
    accent-color: #df1995;
    border-radius: 10px !important;
}
input[type="radio" i]{
    width: 15px !important;
    height: 15px;
}
input[type="file" i] {
    padding-top: 20px;
}
form{
    text-align:left !important;
}
.input-wrapper span{
    padding-left: 5px !important;
}
.input-wrapper a{ 
    color: #000;
    font-weight: 600;
}
input[type=submit] {
    border-radius: 50px;
    background-color: #DF1995;
    font-weight: 600;
    color: #fff
}
input[type=submit]:disabled {
    background-color: #df1995b8;
}

.guidebook{
    
    padding: 40px 0px 100px 0px ;
   
    margin: 30px auto -100px auto;
}
.guidebook-container{
    display: flex;
    
    align-items: flex-end;
    background-image: url(../../../Assets/guidebook-background.png);
    background-size: 100% 100%;
    border-radius: 20px;
    justify-content: space-between;
    margin: auto;
    max-width: 1240px;
}
.guidebook .secondary-heading{
    text-align: left;
    color: #fff !important;
    max-width: initial;
    padding-top: 8px;
}
/*.light-heading{
    font-weight: normal !important;
}*/
.guidebook-container .avatar{
    padding: 20px 20px 0px 20px;
}

.avatar img{     margin-bottom: -8px;}
.guidebook-container .desc{
    padding: 65px 0px 65px 56px;
    color: #fff !important;
}
.desc p{ 
    margin: 15px 0px;
    width: 680px;
}
.get-guidebook{
    margin-top:40px
}
.get-guidebook .email-input{
    border-radius: 50px;
    position: relative;
    height: 64px;
    width: 539px;
}
.get-guidebook .submit-button{
   height: 54px;
    width: 205px;
    margin-left: -210px;
    border: none;
}

.packages{
    padding: 100px 0px 40px 0px   }
        .packages-container {
            display: flex;
            grid-template-columns: auto auto auto;
            justify-content: center;
          width: 100%;
            margin: auto;
            margin-top: 60px ;
        }
        .package{
            position: relative;
            margin: 15px;
            background-color: #fff;
    width: 360px;
    padding: 30px;
    border-radius: 20px;
box-shadow: 0px 5px 60px #817a9924;
        }
        .package-title , .package-price{
            text-align: center;
        }
        .package-title {
            font-size: 36px;
        }
        .package-price{
            font-size: 40px;
            color: #df1995

        }
        .package-decription{
            color:#000;
            font-size: 16px;
            padding: 20px 0px
        }
        .package-decription .css-vubbuv{
            color: #df1995 !important;
        }
        .desc-item{
            display: flex;
            gap: 15px;
        }
        .desc-item span{
            color: #817a99;
        }
       
        .desc-item .css-i4bv87-MuiSvgIcon-root{
            color: #df1995 !important
        }
        .collaps{
            padding: 20px 0px 0px 0px;
        }
        .collaps .sub-title{  font-size: 14px;
            line-height: 1;
            padding-left: 20px;

        }
        .collaps-button{
            background-color: transparent;
            color: #DF1995;
            border: none;
            font-size: 12px;
            text-decoration: underline;
            padding-left: 20px;

            font-weight: 700;
            cursor: pointer;
        }
        .collaps h4{
            background-color: transparent;
            color: #DF1995;
            font-size: 18px;
padding-left: 20px;
            font-weight: 700;
        }
        .toggle , .toggle1{
            width: 100%;
    left: 0;
    position: absolute;
    background: #fff; 
    z-index: 99999;
    padding: 20px 40px;
    border-radius: 20px;
   
        }
        .trainers{
            padding: 60px 0px;
            display: block;
           

        }
        .trainers-container{
            display: flex;
            flex: 2 1;
            gap: 32px;
            justify-content: center;
            margin-top: 60px !important;
            padding: 40px 0px 40px 0px;
            box-shadow: 0px 5px 60px #817a9924;
            
            font-size: 20px;
            font-weight: 500;
            margin: auto;
            max-width: 1128px;
            
        }
      
        .living-page .about-section .trainer-image{
            height: 100% !important; 
            width: 100% !important;
            position: relative;
        }
        .living-page .trainer-image > div:nth-of-type(1),  .content-page .trainer-image > div:nth-of-type(1){

            height: 100% !important;
        }
        .trainers-services .trainers-container{
            padding: 40px 0px 50px 0px;
        }
        .trainer-image{
            padding: 10px;
            background-image: url(../../../Assets/trainer-background.svg);
            background-repeat: no-repeat;
            background-size: contain ;
            width: 250px;
            height: 250px;
        }
        .trainer-image img{
            border-radius: 50%;

        }
        .trainer-image h4 , .trainer-image p{
            color: #DF1995 !important;
            text-align: center;
        }
        .trainer-desc{
            max-width: 680px;
        }
        .trainer-desc p{
            margin-bottom: 20px;
        }
        .react-tel-input .country-list .country:hover {
            background-color: #f1f1f1 !important;
        }
        .right-section li:hover {
            background: gray;
            cursor: pointer;
            color: #000;}
            .react-tel-input .flag-dropdown.open .selected-flag{
                background-color: transparent !important;
            }
            .arabic-service .features-container{
                flex-wrap: wrap;
                width: 80%;
                margin: auto;
            }
            .arabic-service .features{
                direction: rtl;
                text-align: right;
            }
            .arabic-service .features .feature{
                background-image:url(../../../Assets/workshop-features-background.png);
                width: 350px;
                /*background-size: cover;*/
                margin: auto !important;
                height: 300px;
            }
            .arabic-service .workshops-trainers{
                padding: 60px 0px 60px 0px;
            }
            .arabic-service  .trainers-container{
                flex-direction: column;
            }
            .trainers-presenter{
                display: flex;
                gap: 30px;
            }
            .trainers-presenter .trainers-container{
                padding: 20px 0px ;
            }
            .trainers-presenter .trainer-desc p{
                margin: 10px;
            }
        @media (min-width: 1290px){
            .banner-service{
                padding-top: 7%;
            }
        }
@media (max-width: 760px) {
    .features-container, .testimonials-container, .booking , .guidebook-container, .packages-container, .trainers-container{
        flex-direction: column;
        align-items: center;
    }
    .banner-service{
        padding-top: 10px;
    }
    .hero-section{
        background-image: url('../../../Assets/banner-service-background-mobile.webp');
    }
    .banner-service-image{
        padding: 38px 0px;
    }
    .features{
        margin-top: 30px;
    }
    h1, .features h2 , .testimonials h2, .trainers h2{
        font-size: 40px !important;
    }
    .feature{
        background-image: url(../../../Assets/how-it-work-image.png);
         min-height: 207px;
       width:  331px;
        min-height: 207px;
    }
    .video-section{
        padding: 30px 0px;
    }
    .booking .left-section{
        width: auto;
        text-align: center;
    }
    .left-section-container .third-heading{
        font-size: 23px;
    }
    .content-page .third-heading{
        font-size: 22px !important;
       }
    .left-section-container .secondary-heading{
        text-align: center !important;
    }
    .booking, .testimonials{
        padding: 50px 15px 40px 15px;
    }
    .booking .right-section{
        width: auto;
    }
    input , .react-tel-input .form-control{
        width: 290px !important;
    }
    .form-section form{
        padding: 40px 25px;
    }
    .guidebook-container .desc{
        width: 100%;
        padding: 35px 20px 30px 20px;
    }
    .guidebook .secondary-heading{
        text-align: center;
        font-size: 34px;
    }
    .desc p{
        width: auto;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
    }
    .get-guidebook .submit-button { 
        margin-left: 0px;
        height: 64px;
        margin: 10px 0px;
    }
    .react-multi-carousel-list{
        display: block !important;
    }
    .testimonials-container{
        display: none;
    }
    .testimonail{
        box-shadow: none;
        padding: 20px;
        flex-direction: column;
    }
    .trainers-container{
        padding: 60px 0px 30px 0px;
    }
    .trainer-desc{
        padding: 20px 10px 0px 10px ;
        text-align: center;
    }
    .trainers-presenter .trainers-container{
        gap: 20px !important;
    }
    .trainers-presenter .trainer-desc{
        padding: 10px 30px 0px 30px ;
        text-align: center;
    }
    .light-heading , .light-heading2{
        font-size: 40px;
    }
    .arabic-service .feature-icon{
        padding: 16px 10px;
    }
    .package{
        width: 325px;
    }
    .arabic-service .features .feature{
        width: 325px;
    }
    .trainers-services .trainers-container{
        padding: 25px 0px 15px 0px;
    }
    .trainers-services  .trainer-desc{
        padding: 40px 10px 0px 10px;
    }
    .get-guidebook input{
        width: 100% !important;
    }
    .living-page .second-features .features-container{
        padding: 0px !important;
    }
    .living-page .features{
        margin: 0px !important;
    }
}
@media (max-width: 362px){
    .testimonail{
        
        width: 330px;
    }
}

/*carousel*/
.react-multi-carousel-list{
    
    padding: 0px 0px 50px 0px;
}
.react-multi-carousel-item{
    border-radius: 20px;
}
.react-multi-carousel-item--active{
    z-index: 9999;
    background-color: #fff !important;
}
.react-multi-carousel-dot-list{
    top: 380px;
    z-index: 9999999999;
    
}
.react-multi-carousel-dot--active button {
    width: 15px !important;
    background: #080808;
    border-color: black;}
    .react-multi-carousel-dot button {
       width: 6px;
       height: auto; 
        border-radius: 30px;
        margin-right: 3px;
        
    }
    .arabic-page .light-text{
        direction: rtl;
    }
   /*living by design page*/
   .living-page .hero-section{
    background-image: url(../../../Assets/banner-livingbydesign-background.png) !important;
   }
   .service-logo{
    width: 300px;
    margin: auto;
   }
   .living-page .light-text{
    margin: 15px auto 42px;
   }
   .about-section{
    margin: 120px 0px 90px 0px;
   }
   .about-section h2{
    font-size: 52px !important;
   }
   .about-section .trainer-image img{
    border-radius: 20px;
    height: 375px;
   }
   .about-section .trainer-image{
    background-image: none;
    width: 500px !important;
    height: 300px;
    padding: 0px;
   }
   .living-page .trainers-container{
    padding: 0px;
    max-width: 1330px;
    justify-content: space-between;
    padding: 20px;
   }

   .living-page .trainer-desc p{
    margin-bottom: 18px;
   }
   .second-features .feature{
    background-image: url(../../../Assets/about-background.svg);
    width: 350px;
    height: 400px;
   }
   .living-page .third-heading{
    font-size: 28px;
   }
   .content-page .third-heading{
    font-size: 26px;
   }
   .content-page .primary-heading{
    font-size: 42px;
   }
   .content-page .service-logo{
    width: 500px !important;
   }
   .content-page .cta-quiz {
    margin-top: 60px;
}
   .living-cta{
    padding: 15px 30px ;
    font-size: 22px;
   }
   .second-features .features-container{
    margin: 80px 0px ; 
   }
   .service-logo{
    padding: 30px 0px 10px 0px ;
   }
   .four-images{
    /*width: 60% !important;*/
    margin: 30px auto;
   }
   .SBD-AboutImage{
    position: absolute;
    left: 8%;
    bottom: -5px;
}
   @media (max-width: 767px) {
    .living-page .trainers-container{
        height: auto !important;
        padding: 0px;
    }
    .content-page .trainers-container{
flex-direction: row;
    }
    .content-page .primary-heading , .living-page .primary-heading{ 
        font-size: 32px !important;
    }
    .content-page .features h2{
        padding: 20px 5px !important;
    }
    .SBD-AboutImage{
        bottom: -15px !important;
        right: -130px !important;
        
        left: auto !important;
    }
    .content-page .trainer-image img{
        height: 400px !important;
    }
    .four-images{
        width: 100% !important;
    }
    .content-page .trainer-desc {
        padding: 20px 100px 10px 15px !important;
        text-align: left;
    }
    .content-page .trainer-desc p {
        margin-bottom: 10px !important;
    }
        .textAboutMobile{
            width: 230px;
        display: block;
        }
        .content-page .trainer-desc p{
            line-height: 20px;
            font-size: 14px;
        }
   .last-text{
   }
   .about-section .trainer-image{
    width: 350px !important;
    height: 230px !important;
   }
   .about-section .trainer-image img{
    height: 300px;
    border-radius: 20px !important;
   }
   .service-logo{
    width: 200px;
   }
   .living-page .hero-section{
    background-image: url(../../../Assets/banner-living-mobile-background.png) !important;
   }
   .second-features .features-container{
    margin: 80px 0px 0px 0px 
   }
   .about-section h2{
    font-size: 40px !important;
   }
   .content-page .light-text{
    margin:  10px auto 20px !important;
}
}
/*content by design page*/
.content-page .hero-section{
    background-image: url(../../../Assets/SBDBack2.svg);
    
   
}
.content-page .light-text{
    margin:  27px auto 42px;
    
        line-height: 30px;

}
.content-page .trainer-image img{
height:  380px;
border-radius: 20px 0px 0px 20px;
}
 .content-page .trainers-container{
    position: relative;
justify-content: space-around;
padding: 0px;
align-items: center;
 }
 .content-page .trainer-image{
    height:330px !important;
    width: auto !important;
 }
 .content-page .trainer-desc{
    padding: 30px 20px 10px 80px;
    font-size: 17px !important;
 }
 .content-page .trainer-desc p {
    margin-bottom: 20px;
 }
 .content-page .trainer-desc .s1::after{
    content: "\a";
    white-space: pre;
 }
 .content-page .trainer-desc .s2::after{
    content: "\a";
    white-space: pre;
 }
 .content-page .trainer-desc .s3::after{
    content: "\a";
    white-space: pre;
 }
 .content-page .trainer-desc .s4::after{
    content: "\a";
    white-space: pre;
 }
 @media (max-width: 767px) {
 .content-page .hero-section{
    background-image: url(../../../Assets/banner-content-mobile-background.png);
   }
   .content-page .trainer-desc .s2::after{
    content: "";
    white-space: pre;
 }
 .content-page .trainer-desc .s3::after{
    content: "";
    white-space: pre;
 }
 .content-page .trainer-desc .s4::after{
    content: "";
    white-space: pre;
 }
 .content-page .trainer-desc .s5::after{
    content: "\a";
    white-space: pre;
 }
 .content-page .trainer-desc .s6::before{
    content: "\a";
    white-space: pre;
 }
}

.SBD-Logo-section{
    width: 100% !important;
    text-align:center
}

.before-state , .after-state{
    position: absolute;
    top: 10px;
    background-color: rgba(223, 25, 149, 0.7);
    color: #fff !important;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 50px;
}

.before-state{
    left: 10px;
}
.after-state{
    right: 10px;
}

/* The Modal (background) */
.modal-button{
    padding-top: 0.5rem;
padding-bottom: 0.5rem; 
padding-left: 1rem;
padding-right: 1rem; 
color: #ffffff; 
font-size: 0.875rem;
line-height: 1.25rem; 
font-weight: 500; 
border-radius: 0.375rem; 
}
.dialog-container{
position: relative; 
z-index: 10; 
}
/*thankyou page*/
.thankyou-page{
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 150px 50px 50px;
}
.form-text{
    text-align: center;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
}
@media (max-width: 760px) {
    .form-text{
        text-align: center;
    }
}
/*Personal coaching services*/
.before-cta{
    margin: 10px auto !important;
}
.mobile-v{
    display: none;
}
@media (max-width: 780px) {
    .desktop-v{
        display: none;
    }
    .mobile-v{
        display: block;
    }
    .about-section{
        margin: 50px 0px;
    }
}
.sliderTray___-vHFQ .sliderAnimation___300FY .carousel__slider-tray .carousel__slider-tray--horizontal{
    width: 280% !important;
}
.carousel__dot-group{
    text-align: center;
    margin: auto;
}
.carousel__dot{
    width: 8px;
    height: 8px;
    background-color: transparent;
    border: 1px #848D94 solid;
    border-radius: 100%;
    margin: 0px 5px
}
.carousel__dot--selected{
    background:#848D94 ;
}
.mobile-v .feature{
    margin: auto;
}
.desktop-v .feature{
    /*height: auto !important;*/
    transition: all linear 0.3s;
    height: 300px;
    position: relative;
}
.living-page .second-features .feature{
    background-image: url(../../../Assets/newFetureBakg.svg);
}

.second-features .desktop-v .feature{
    height: 460px;
}
.desktop-v .feature-heading{
    width: 100%;
    padding: 10px 20px;
    font-size: 32px;
    text-align: center;
    position: absolute; top: 50%; transform: translateY(-20%);
}
.desktop-v .feature-desc{
    display: none;
    opacity: 0; /* Set initial opacity to 0 */
    transition: all linear 0.3s;
    padding: 0px 20px;
}
.desktop-v .feature:hover .feature-heading{
font-size: 20px;
padding: 30px 20px 0px 20px;
text-align: left;
position: static; 
}
.desktop-v .feature:hover .feature-desc{
    display: block;
    /* Add your transition styles here */
    opacity: 1; /* Ensure the description is visible when hovered */
    transition: all linear 0.3s;
}
.living-page .features-container{
    transition: all linear 0.3s;
    /*align-items: flex-start !important*/
}
.content-page .feature {
    background-image: url(../../../Assets/SBDFeatureImage3.svg);
}
.content-page .desktop-v .feature{
    height: 350px;
}
.content-page .second-features .desktop-v .feature{
    height: 460px;
}
.content-page .second-features .feature{
    background-image: url(../../../Assets/newFetureBakg.svg);
}
.horizontalSlider___281Ls{
    overflow: visible !important;
}
.content-page .trainers-container{
    background-color: #fff;
    gap: 5px;
}
.living-page .booking , .content-page .booking{
   
    gap: 40px
}
.living-page .booking .left-section{ 
    display: flex;
    align-items: flex-end;
}
.content-page .booking .left-section{ 
    display: flex;
    align-items: flex-end;
}
.cta-quiz{
    margin-top: 50px;
}
.arrow-quiz{
    margin-bottom: 10px;
}
.arrow-mobile-v{
    display: none;
}
@media (max-width: 780px) { 
    .arrow-desctop-v{
        display: none;

}
.arrow-mobile-v{
    display: inline-block;
    position: absolute;
    left: 0px;
    margin-top: -10px;
    width: 70px;
}}
.voice-page .workshop-intro .third-heading{
    font-size: 24px;
}
.voice-page .video-section{
    padding: 0 !important;
    max-width: 50%;
}
.voice-page .video-section video{
    height: 600px;

}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    font-family: 'baloo' !important;
    font-size: 18px !important;
}
.voice-page .hero-workshop-section p{
    font-size: 18px !important;
    max-width: 500px;
}
.voice-page .hero-workshop-section .cta-button{
    margin-top: 0px !important;
}
.css-19kzrtu{
    padding: 24px 0px !important;
}
.voice-page .hero-workshop-section{
    gap: 100px !important;
    max-width: 1400px;

}
.job-program-hero .hero-workshop-section{
    gap: 200px !important;
}
.program-process  .workshop-features-section .features{
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}
.program-process  .workshop-features-section .feature {
    background-image:none;
    width: 100% !important;
    height: 138px;
    background-color: #f4d5d8;
    text-align: right;
    height: auto !important;
    padding: 50px !important;
}
.voice-page .hero-workshop-section a{
    color: #DF1995;
}
.workshops-booking .input-wrapper span.Dropdown-arrow{
    padding-right: 0px !important;
    padding-left: 0px !important;
    top: 20px;
}
.Dropdown-control{
border: 1px solid !important;
    border-color: #e7e7e7 !important;
    border-radius: 12px !important;
   }
   .voice-page .workshop-date {
    justify-content: right !important;
}

@media (max-width: 760px) {
    .voice-page .css-ahj2mt-MuiTypography-root{
        text-align: right !important;
        margin-right: 10px;
        
    }
    .voice-page .css-ahj2mt-MuiTypography-root p{
        font-size: 16px !important;    }
    .voice-page .video-section video{
        height: auto !important;
    
    }
    .voice-page .hero-workshop-section{
        gap: 10px !important;}
        .voice-page .video-section{
            max-width: 100%;
        }
}
.css-1q2h7u5{
    font-family: 'baloo' !important;
    font-size: 18px !important;
}

/* Slider.css */

  
 
  
  .dots-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 6px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dot.active,
  .dot:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  .slider img{ 
    border-radius: 10px;
  }

  .hoverSectionvedio {
    display: none; /* Initially hidden */
  }
  
  .hoverButtonSectionvedio {
    cursor: pointer; /* Change cursor to pointer */
  }
  
  /* Show the hoverSectionvedio when hoverButtonSectionvedio is hovered */
  .hoverButtonSectionvedio:hover + .hoverSectionvedio {
    display: block; /* Show the content */
    
  }
  .desktop-view1{
    position: relative;
  }
  .desktop-view1 .trainer-desc{
    width: 65%;

  }
  .desktop-view1 .trainer-desc .project-title{
    text-align: center;
    font-size: 24px;
  }
  /* Default Desktop View */
.desktop-view1 .hovervedio {
    display: none; /* Hidden by default on desktop */
  }
  .desktop-view1 .app{
    width: 50%;
  }
  .desktop-view1 .trainer-desc:hover .hovervedio {
    display: block; /* Show on hover for desktop */
    opacity: 1;
    position: absolute;
    padding: 20px;
    top: -12%;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .desktop-view1 .trainer-desc:hover .project-title {
    display: none; /* Hide the title on hover */
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .desktop-view1 {
      display: none; /* Hide the desktop view on mobile */
    }
  
    .mobile-view1 {
      display: block; /* Show the mobile view */
    }
    
    .mobile-view1 .trainer-desc .hovervedio {
      display: block; /* Always show on mobile */
      opacity: 1;
      padding: 10px;
      background-color: #fff;
      border-radius: 10px;
      margin-top: 10px; /* Adjust margin to separate from the video */
    }
  
    .mobile-view1 .trainer-desc .project-title {
      display: block; /* Show the project title */
      margin-bottom: 0px; /* Add margin to separate from the description */
      font-size: 24px; /* Adjust font size for better visibility */
      text-align: center; /* Center align the title */
    }
  
    .mobile-view1 .trainer-desc {
      position: relative; /* Adjust positioning for mobile view */
      text-align: center; /* Center-align content */
      margin: 20px 0; /* Add spacing */
      background-color: #fff;
      border-radius: 10px;
    }
  
    .mobile-v1 .carousel__inner-slide, .mobile-v1 .mobile-v .carousel__slider {
      display: flex;
      align-items: center; /* Center content vertically */
      justify-content: center; /* Center content horizontally */
      height: 100%; /* Make sure the slide takes full height */
    }
  
    .mobile-view1 .App {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%; /* Make sure the video slide takes full height */
    }
    .mobile-view1 .trainer-desc p{
        margin-bottom: 5px;
    }
    .mobile-view1 video{
        margin: 70px 0px;
    }
    .mobile-view1  .trainer-desc p{
        font-size: 14px !important;
        margin-bottom: 12px;
    }
  }
  .form-organizer{
    display: flex;
  }
  .form-organizer input{
    width: 95% !important;
    gap:10px
  }

  @media (max-width: 1290px) {
    .program-process .workshop-features-section .feature {
         max-width: 100% !important; 

    }
}