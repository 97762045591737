
@font-face {
  font-family: baloo;
  font-style: bold;
  font-display: swap;
  src: url('https://cdn.alaan.tv/nuz/fonts/baloo-bhaijaan-2-arabic.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
@font-face {
  font-family: baloo;
  font-style: bold;
  font-display: swap;
  src: url('https://cdn.alaan.tv/nuz/fonts/baloo-bhaijaan-2-arabic.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}
@font-face {
  font-family: baloo;
  font-style: bold;
  font-display: swap;
  src: url('https://cdn.alaan.tv/nuz/fonts/baloo-bhaijaan-2-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: baloo;
  font-style: bold;
  font-display: swap;
  src: url('https://cdn.alaan.tv/nuz/fonts/baloo-bhaijaan-2-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@import "~@flaticon/flaticon-uicons/css/all/all";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: baloo, sans-serif;}
  html{
    overflow-x: hidden;
  }
  a {text-decoration: none !important;
    cursor: pointer !important ;
   }
   button{
    cursor:  pointer !important;
   }
img {
  max-width: 100%;
  height: auto;
}
html {
  scroll-behavior: smooth;


}
.App {
  

  margin: 0rem auto;
}

body {
  width: 100%;
  
}

body {
  background-color: #f6f6f6;
}



.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}
.primary-button:hover {
  background-color: rgb(234, 234, 234);
}


.home-container{
  width: 90%;
  margin: auto;
}
.home-banner-container {
  position: relative;
  display: flex;
  /*padding-top: 80px;*/
}
.home-image-container{
  text-align: center;
  /*padding: 20px 0px;*/
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.banner-image{
  border-radius: 20px;
}
.home-bannerImageLeft-container{
  position: absolute;
  top: 300px;
  left: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8% 0px 18% 0px;
}
.home-text-main-section{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8% 0px;
}
.advertise-section{
  max-width: 1240px;
  margin: auto;
  padding: 40px;
  margin-top: 140px;
  background-image: url(Assets/banner-backround.png);
  background-size: 100% 100%;
    border-radius: 20px;
    background-repeat: no-repeat;
}
.advertise-section-container{
  display: flex;
  gap: 58px;
  flex-direction: row;
  align-items: center;
}
.advertise-image img{
  max-width: 250px;
  margin-bottom: -20px;
}
.advertise-text{
  padding: 20px 0px 0px 0px;
}
.primary-heading {
  font-size: 64px;
  color: #000;
  text-align: center;
   line-height: 106%;
  /*width: 980px;*/
  max-width: 980px;
  margin: auto;
}
.primary-text {
  font-size: 24px;
  /*width: 818px;*/
  max-width: 818px;
  color: #817A99;
  margin: auto;
  margin-top: 40px;
  text-align: center;
}
.secondary-heading{
  font-size: 52px;
  color: #000;
  text-align: center;
   line-height: 106%; 
  /*width: 980px;*/
  max-width: 980px;
  margin: auto;
}

.secondary-text{
  font-size: 24px;
  /*width: 818px;*/
  max-width: 818px;
  color: #817A99;
  margin: auto;
  margin-top: 10px;
  text-align: center;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}
.third-heading{
 font-size : 32px;
 font-weight: 600;
}
.third-text{
  font-size: 18px;
  color: #817A99
}
.banner-heading{
  font-size: 34px;
  color: #fff;
  font-weight: 700;
   line-height: 106%; 
 
  /*width: 980px;*/
  
}
.banner-text{
  font-size: 24px;
  /*width: 818px;*/
  
  color: #fff;
  
  margin-top: 22px;
 
}
.banner-button{
  all: unset;
align-items: center;
background-color: #ffffff;
border-radius: 50px;
box-sizing: border-box;
display: flex;
color: #DF1995;
gap: 10px;
height: 64px;
justify-content: center;
padding: 21.5px;
position: relative;
width: 220px;
font-size: 20px;
font-weight: 600;
}
.banner-button:hover{
  background-color: rgb(234, 234, 234);
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}
.footer-wrapper {
  margin: 6rem 0rem;
  display: flex;
  margin-top: 10rem;
}
.footer-logo-container {
  max-width: 110px;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}
/*Our Brands Section */
.our-brands, .our-services2{
  background-image: url(Assets/brands-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  max-width: 1242px;
  margin: auto;
  padding : 50px;
  border-radius: 20px;
 }
 .our-services2{
  background: transparent !important;
  padding : 0px;
  margin: auto;
  /*margin-top: 98px;*/

 }
 
 .service-desc p{
 margin-right: 100px;
   
 }
 .service-desc img{
  float: right;
  margin-top: -25px;
  
 }
 .our-services h1{
  padding: 26px 0px;
}
.our-brands h2{
  padding: 15px 0px;
}
.our-brands-container, .our-services-container2{
 
  display: flex;
  flex-direction: row;
  gap: 34px;
}
.our-services-container2{
  flex-wrap: wrap;

}

.service-card{
  background-color: #fff;
  width: auto;
  border-radius: 22px;
  padding: 28px 24px;
}
.brand-card{
  background-color: #fff;
  width: auto;
  border-radius: 22px;
  padding: 28px 24px 15px 24px;
}
.service-card{
  width: 390px;
  position: relative;
}
.service-card-icon{
  /*height: 140px;*/
}
.sevice-more-icon{
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.service-card:hover .sevice-more-icon{
  opacity: 0.7;
}
.workshops-services{
  /*flex-wrap: nowrap;*/
}
.workshops-services .service-card{
  /*width: 50%;*/
}
.brand-card h2 ,.service-card h2{
  margin: 26px 0px 7px 0px;
}
.brand-card p, .service-card p{
  height: 40%;
}
.brand-links{
  padding: 2px;
  text-align: center;
}
.social-icons {
  background-color: #df199514;
  color: #DF1995;
  border-radius: 40px;
  margin-right: 4%;
  text-align: center;
  padding:7px;
  width: 38px !important;
  height: 38px !important;
  justify-items: center;
  
}
.link-icon{
  transform: rotate(-30deg);
}
.social-icons .link-instance {
  height: 24px !important;
  left: 9px !important;
  position: absolute !important;
  width: 24px !important;
}
.divider2 {
  background-color: rgba(41, 45, 52, 0.1);
  align-self: center;
  width: 100%;
  height: 1px;
  display: inline-block;
}
/*our features*/
.our-features{
  display: flex ;
  gap: 40px;
  flex-direction: row;
  text-align: center;
  max-width: 1240px;
  margin: auto;
  margin-top: 50px;
  text-align: center;
 
}
.our-services-container{
  
 position: relative;
}
.features-desc{
  position: absolute;
  bottom: 45px;
  display: flex;
  width: 100%;
  padding: 0px 30px 0px 40px;
  justify-content: space-between;
  align-items: flex-end;
}
.features-desc h2{
  font-size: 52px;
  color: white;
  width: 192px;
  text-align: left;
  line-height: 106%;
}
.features-desc img{
  max-width: max-content !important;
}
.our-services:hover picture{
  opacity: 0.7;
}
.our-workshops:hover picture{
  opacity: 0.7;
}
.home-arabic-features .features-desc{
direction: rtl;
padding: 0px 40px 0px 30px;

}
.home-arabic-features .features-desc h2{
  font-size: 48px;
  text-align: right;
  width: 100%;
}
.home-arabic-features .features-desc picture{
  transform: rotate(180deg);
}
@media (max-width: 1000px) {
 
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 760px) {
  .home-container{
    overflow: hidden;
    width: 95%;
  }
  
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .home-image-container{
    padding-top: 30px;

  }
  .home-text-section{
  }
  .primary-heading {
    text-align: center;
    font-size: 40px;
  }
  .arabic-page .primary-heading {
    text-align: center;
    font-size: 34px !important;
    line-height: 150%;
  }
  .arabic-page .secondary-heading{
    font-size: 34px !important;
    line-height: 150%;
  }
  .arabic-page .light-heading{
    font-size: 34px !important;
    line-height: 150%;
  }
  .primary-text {
    text-align: center;
    font-size: 19px;
  }
  .secondary-heading {
    text-align: center;
    font-size: 40px;
  }
  .secondary-text {
    text-align: center;
    font-size: 19px;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    /*margin-top: 47px;*/
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .our-brands {
    padding: 20px;
    text-align: center;
    margin-top: 30px;
  }
  .brand-card{
    padding: 24px 24px 5px 24px !important
  }
  .arabic-brands{
    text-align: center !important;
  }
  .our-brands-container , .our-services-container2{
    flex-direction: column;
  }
  .our-features{
    flex-direction: column;
  }
  .features-desc{
    position: absolute;
    bottom: 40px;
    display: flex;
    width: 100%;
    padding: 0px 20px 0px 30px;
    justify-content: space-between;
    align-items: flex-end;
  }
  .features-desc h2{
    font-size: 34px !important;
    color: white;
    width: 192px !important;
    text-align: left;
    line-height: 106%;
  }
  .features-desc img{
    width: 80%;
  }
  .advertise-section{
    padding: 30px 10px;
    text-align: center;
    margin-top: 70px;
  }
  .advertise-section-container{
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    justify-items: center;
  }
  .banner-text{
    font-size: 19px;
  }
  .advertise-text{
    padding: 0px;

  }
  .workshops-services .service-card{
    width: 100%;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    /*width: 90%; */ }
  .service-card{
    max-width: 350px;
    width: auto !important;
    margin: auto;
  }
  .service-desc p{
    margin-right: 50px;
  }
  .brand-card h2, .service-card h2{
    margin: 8px 0px 7px 0px ;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}
@media (max-width: 300px) {

body{
  overflow-x: hidden;
}



}
.clear{
  clear: both ;
}

/*Arabic version */
/*Homepage*/
.arabic-home-text-section .primary-heading{
  line-height: 140%;
}
.arabic-brands{
  text-align: right;
}
.arabic-brands .brand-card{
  padding-bottom: 35px;
}
input[type=submit]{
  cursor: pointer !important;
}
input[type=submit]:hover{
  background-color: #cb198a;
  
}
/*Workshops Arabic Page*/
.workshops-arabic{direction: rtl;}

.workshops-arabic .sevice-more-icon{
  left: 20px !important;
  right: auto;
  transform: rotate(180deg);
}
.workshops-arabic .service-desc p{
  margin-left: 100px;
  margin-right: auto;
}


